import * as React from "react"
import {graphql} from "gatsby";
import { render } from 'datocms-structured-text-to-html-string';

import StaticPageTemplate from "./../components/staticPageTemplate"

import SEO from './../components/SEO';

export const getMonument = graphql`
query getMonument {
    datoCmsMemorial{
      title,
      body {
        value
      }
    }
  }
`
  

const MonumentPage = ({data}) => {
  return (
    <StaticPageTemplate title={data.datoCmsMemorial.title}>
      <SEO title="Miejsce Pamięci Dzieci Utraconych w Dębicy - o pomniku" link="https://debica-dzieciutracone.pl/o-pomniku" ogTitle="O pomniku - Miejsce Pamięci Dzieci Utraconych w Dębicy" description="O pomniku, który stanowi miejsce pamięci i modlitwy dla rodziców, którym nie było dane pożegnać i pochować swoich dzieci."/>

       <div className="DatoCMS" dangerouslySetInnerHTML={{__html: render(data.datoCmsMemorial.body.value)}}></div>
    </StaticPageTemplate>
  )
}

export default MonumentPage